import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@syncfusion/ej2-angular-popups';
import { PipesModule } from '../pipes/pipes.module';
import { DataHandlerComponent } from './data-handler.component';

const components = [DataHandlerComponent];
const moduleDependencies = [CommonModule, TooltipModule, PipesModule];

@NgModule({
    declarations: [components],
    imports: moduleDependencies,
    exports: [components]
})
export class DataHandlerModule { }
