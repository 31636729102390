import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {
  constructor() { }
 
  //The set method is use for encrypt the request body.
  set(keys, value){
    let randomStringKey = this.randomString();

    let key = CryptoJS.enc.Utf8.parse(keys + randomStringKey);
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(JSON.stringify(value)), key,
    {
        keySize: 192 / 8,
        mode: CryptoJS.mode.ECB,
    });

    return randomStringKey + encrypted.toString();
  }

  //The get method is use for decrypt the response body.
  get(keys, value){
    let dynamicKey = (value.slice(0, 12));
    let key = CryptoJS.enc.Utf8.parse(keys + dynamicKey);
    let dataToDecrypt = value.replace(dynamicKey,'')
    
    let decrypted = CryptoJS.AES.decrypt(dataToDecrypt, key, {
        keySize: 192/8,
         mode: CryptoJS.mode.ECB,
    });
        return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  }

  // For Generating 12 chars random string from below characters
  randomString() {
    let length = 12;
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result = '';
    for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
    return result;
  }

}
