import { Store } from "src/app/core/store/store";

export const apis = {
  websocket: {
    // stomp: {
    //   endpoint: 'http://10.10.10.218:8080/api/ws/notify/unread-count/',
    // },
    // broadcastStomp: {
    //   endpoint: 'http://10.10.10.134:9191/api/ws/broadcast/message'
    // }
  } as Store.APIConfigGroup,

  auth: {
    me: {
      endpoint: "/user/me",
    },
    validate: {
      endpoint: "/auth/validate",
    },
    forgotPassword: {
      endpoint: "/auth/forgot-password",
    },
    verifyOtp: {
      endpoint: "/auth/otp/token",
    },
    verifyEmail: {
      endpoint: "/auth/verify-email",
    },
    CTAConfiguration: {
      endpoint: "/record/configuration/list",
    },
    verifyUsername: {
      endpoint: "/auth/ldap/verify-username",
    },
    getVersion: {
      endpoint: "/config/version",
    },

    validateLicense: {
      endpoint: "/license/activate",
    },
  } as Store.APIConfigGroup,

  users: {
    userList: {
      endpoint: "/user/list",
    },
    userFromAD: {
      endpoint: "/auth/ldap/users",
    },
    userType: {
      endpoint: "/master/list",
    },
    addUser: {
      endpoint: "/user/add",
    },
    deleteUser: {
      endpoint: "/user/delete-user  ",
    },
    EnableDisableUser: {
      endpoint: "/user/change-status",
    },
    recordcountByUserId: {
      endpoint: "/record/user-count",
    },
    changeUserType: {
      endpoint: "/user/update-user-type",
    },
    removeaccess: {
      endpoint: "/user/remove-user-access",
    },
    activateUser: {
      endpoint: "/user/activate-user",
    },
    refreshSync: {
      endpoint: "/license/sync",
    },
    licenseUserType: {
      endpoint: "/user/get-available-user-types",
    },
    createWorkflowGroup: {
      endpoint: "/group/create",
    },

    getAllWorkflowGroups: {
      endpoint: "/group/get-all-groups",
    },
    getGroupIdByUser:{
      endpoint:"/group/get-group-ids"
    },

    removeMemberFromGroup: {
      endpoint: "/group/remove-member/{groupId}",
    },

    updateWorkflowGroup : {
      endpoint : "/group/update"
    }
  
    
  } as Store.APIConfigGroup,

  session: {
    login: {
      endpoint: "/auth/ldap/login",
    },
    logout: {
      endpoint: "/auth/ldap/logout",
    },
    token: {
      endpoint: "/auth/token",
    },
    tokenRefresh: {
      endpoint: "/token/refresh",
    },
  },

  notifications: {
    getnotifications: {
      endpoint: "/notification/list",
    },

    markAllRead: {
      endpoint: "/notification/mark-all-as-read",
    },

    markSingleRead: {
      endpoint: "/notification/mark-as-read",
    },

    notificationFilterList: {
      endpoint: "/notification-master/list",
    },

    getAuditAreaDetails: {
      endpoint: "/audit-area/details/members/list",
    },

    getRecordsDetails: {
      endpoint: "/record/record-details",
    },
    downloadDocuments: {
      endpoint: "/document/download",
    },
  },

  updateNotification: {
    endpoint: "/in-app-notifications/glance",
  },

  objectives: {
    getDetails: {
      endpoint: "/objectives/get-details/{objectiveId}",
    },
    getBreadcrumbs: {
      endpoint: "/audit-area/breadcrumb",
    },
    add: {
      endpoint: "/objectives/add",
    },
    edit: {
      endpoint: "/objectives/update/{id}",
    },
    exportToXLSX: {
      endpoint: "/getObjectiveXL",
    },
    exportToCSV: {
      endpoint: "/getObjectiveCSV",
    },
    getAssignedAuditAreas: {
      endpoint: "/assigned-audit-areas",
    },
    getQuestionnaireList: {
      endpoint: "/audit-tree/questionnaire/list",
    },
    uploadDocuments: {
      endpoint: "/document/upload",
    },
    saveDocuments: {
      endpoint: "/document/save",
    },
    removeDocuments: {
      endpoint: "/document/remove",
    },
    downloadDocuments: {
      endpoint: "/document/download",
    },
  },

  questionnaire: {
    lock: {
      endpoint: "/audit-tree/questionnaire/lock",
    },
    unlock: {
      endpoint: "/audit-tree/questionnaire/unlock",
    },
    addQuestion: {
      endpoint: "/questionnaire/question/draft",
    },
    editQuestion: {
      endpoint: "/questionnaire/{quId}/question/draft",
    },
    copyQuestion: {
      endpoint: "/questionnaire/{quId}/question/copy/{id}",
    },
    deleteQuestion: {
      endpoint: "/questionnaire/{quId}/question/remove/{id}",
    },
    disableQuestion: {
      endpoint: "/questionnaire/{quId}/question/update-status/{id}",
    },
    getQuestionnaireId: {
      endpoint: "/audit-tree/{objId}/questionnaire/list",
    },
    getQuestions: {
      endpoint: "/questionnaire/question/list",
    },
    publishQuestionnaire: {
      endpoint: "/questionnaire/question/publish",
    },
  },

  auditArea: {
    add: {
      endpoint: "/audit-area/add",
    },
    edit: {
      endpoint: "/audit-area/update/{id}",
    },
    list: {
      endpoint: "/audit-area/list",
    },
    members: {
      endpoint: "/audit-area/members/list",
    },
    auditAreaListByUserId: {
      endpoint: "/audit-area/{userId}/audit-area-list",
    },
    search: {
      endpoint: "/global-search/search",
    },
    filter: {
      endpoint: "/global-search/filter",
    },
    details: {
      endpoint: "/audit-area/{id}",
    },
    markAsRead: {
      endpoint: "/audit-area/mark-read",
    },
    getRecordCount: {
      endpoint: "/audit-area/record-count",
    },
    getBreadCrumb: {
      endpoint: "/audit-tree-table/get-level",
    },
  },

  records: {
    list: {
      endpoint: "/record/list",
    },
    details: {
      endpoint: "/record/record-details",
    },
    disown: {
      endpoint: "/record/status/disown",
    },
    invalid: {
      endpoint: "/record/status/invalid",
    },
    close: {
      endpoint: "/record/status/close",
    },
    askForHelp: {
      endpoint: "/record/help/askforhelp",
    },
    helpDetails: {
      endpoint: "/record/help/question-list-details",
    },
    submitHelpAnswer: {
      endpoint: "/questionnaire/question/answer",
    },
    getActivityList: {
      endpoint: "/record/activity/list",
    },
    getPriorityList: {
      endpoint: "/master/list",
    },
    getStatusList: {
      endpoint: "/master/list",
    },
    getNextStatusList: {
      endpoint: "/record/status/{id}/next-status/list",
    },
    addComment: {
      endpoint: "/record/comment/add",
    },
    assignRecord: {
      endpoint: "/record/assign/assign",
    },
    updateRecord: {
      endpoint: "/record/update/records-details",
    },
    processRecord: {
      endpoint: "/record/status/change-status",
    },
    getQuestionsList: {
      endpoint: "/questionnaire/question/list",
    },
    getQuestionsAnsweredList: {
      endpoint: "/questionnaire/question-answer/list",
    },
    saveMaskColumns: {
      endpoint: "/record/masking/mask-columns",
    },
    openRecordCount: {
      endpoint: "/record//open-record-count",
    },
  },

  document: {
    download: {
      endpoint: "/getObjectiveCSV",
    },

    getDocumentList: {
      endpoint: "/document/list",
    },
    downloadZip: {
      endpoint: "/document/download",
    },
    getDocumentDetails: {
      endpoint: "/document/details",
    },
  },

  profile: {
    me: {
      endpoint: "/user/me",
    },
    updateEmailNotifications: {
      endpoint: "/user/update-user-profile",
    },
    getuser: {
      endpoint: "/user/get-user",
    },
  },

  permissionMatrix: {
    getPermissiondetails: {
      endpoint: "/user-subscription-type/permission/matrix/list",
    },
  },

  postIssueMantis: {
    endpoint: "/support/raise-request",
  },

  trigger: {
    getTriggerlist: {
      endpoint: "/trigger/list",
    },

    updateTriggersDetails: {
      endpoint: "/trigger/update",
    },

    columnDetailsList: {
      endpoint: "/trigger/get-column-list",
    },
    addTrigger: {
      endpoint: "/trigger/add",
    },
    previewTrigger: {
      endpoint: "/trigger/preview-trigger",
    },
    runNowTrigger: {
      endpoint: "/trigger/run-now",
    },
    getTriggerLogs: {
      endpoint: "/trigger/get-logs",
    },
    checkDuplicateName: {
      endpoint: "/trigger/check-duplicate-name",
    },
  },

  support: {
    getFAQDetail: {
      endpoint: "/faq/getFaq",
    },

    getLicenseDetail: {
      endpoint: "/license/get-license-data",
    },
  },

  AskForHelp: {
    helpList: {
      endpoint: "/record/help/list",
    },

    AskForHelpDetails: {
      detailsList: {
        endpoint: "/record/help/details/list",
      },
    },

    MarkAsRead: {
      endpoint: "/record/help/details/mark-as-read",
    },
  },

  Permission: {
    audittrail: {
      endpoint: "/audit-trail/list",
    },
  },

  llm: {
    llmLog: {
      endpoint: "/logs/list",
    },
  },
};
