import { Store } from '../store/store';
import { Session, LogoutResponse } from './session.model';


export class SessionCreatingState extends Store.AbstractLoadingState<Session> {}
export class SessionCreatingErrorState extends Store.AbstractErrorState<Session> {}
export class SessionCreatedState extends Store.AbstractIdealState<Session> {}

export class SessionDestroyingState extends Store.AbstractLoadingState<Session> {}
export class SessionDestroyingErrorState extends Store.AbstractErrorState<Session> {}
export class SessionDestroyedState extends Store.AbstractIdealState<Session> {}

export class LogoutLoadingState extends Store.AbstractLoadingState<LogoutResponse> {}
export class LogoutErrorState extends Store.AbstractErrorState<LogoutResponse> {}
export class LogoutLoadedState extends Store.AbstractIdealState<LogoutResponse> {}

export class verifyUserLoadingState extends Store.AbstractLoadingState<any> {}
export class verifyUserLoadedState extends Store.AbstractIdealState<any> {}
export class verifyUserLoadingErrorState extends Store.AbstractErrorState<any> {}

export class GetVersionLoadingState extends Store.AbstractLoadingState<any> {}
export class GetVersionLoadedState extends Store.AbstractIdealState<any> {}
export class GetVersionLoadingErrorState extends Store.AbstractErrorState<any> {}

export class oktaLogoutLoadingState extends Store.AbstractLoadingState<any> {}
export class oktaLogoutLoadedState extends Store.AbstractIdealState<any> {}
export class oktaLogoutLoadingErrorState extends Store.AbstractErrorState<any> {}