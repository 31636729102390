import { Store } from "src/app/core/store/store";
import { isDevMode } from "@angular/core";
import { apis } from "./apis";

// ENV Test
// ENV Test End

export const environment = {
  production: false,
  environment: "DEV",
  product_version: "1.0.9.19",
  referrer: window.frames.top.document.referrer,
  localstorage: {
    key: "traq_workflow",
  },

  // Is this the right way to do this?
 // samlKeyCloak , samlOkta ,openIdKeyCloak ,openIdOkta , defaultJWT, openIdAzure
  protocol: {
     key:"openIdAzure"  
  },
  encryptionDecryption: {
    key: "traqworkflow",
  },
  api: {
    core: {
       //base: 'http://10.10.10.212:8080',
  //   base: 'https://traq-qa.leftrightmind.com:8441/traq_workflow_qa',
  // base: 'http://108.161.131.162:8080/traq_workflow_dev'
   base: "https://traq-dev.leftrightmind.com:8443/traq_workflow_dev",
      //  base:'http://72.249.45.57:8080/traq_workflow_qa'
      // base: 'http://65.0.160.140:8080/traq_workflow_dev'
     // redirectBase:"http://10.10.10.220:8080"
    // base:"https://traq-uat.leftrightmind.com:8441/traq_workflow_demo"
    } as Store.APIConfig,
    // llmBase:{

    // }as Store.APIConfig
    ...apis,
  },

  formats: {
    date: {
      // Moment JS date formats
      default: "MM-DD-YYYY",
      long: "D-MMM-YYYY",
      short: "D-MMM-YYYY",
    },
    dateTime: {
      // Moment JS date formats
      default: "MM-DD-YYYY HH:mm:ss",
    },
  },
};
