<ejs-dialog id="alertModal" [header]="alertData.header" [width]="alertData.width || '600px'" [isModal]="true"
    *ngIf="showModal" [position]="alertData.position || { X: 'center', Y: 'center' }"
    [animationSettings]="{ effect: 'None' }" (close)="closeAlert(false,'CLOSE')" [showCloseIcon]='true'>
    <ng-template #content>
        <div class="text-center">
            <img src="assets/custom/new-warning-icon.svg" alt="Alert icon">
            <h2 class="title"> {{alertData.title}} </h2>
            <p class="message"> {{alertData.message}} </p>
        </div>
    </ng-template>

    <ng-template #footerTemplate>
        <div class="text-center pb-25">
            <ng-container *ngIf="alertData.isConfirmation;else alertBtn">
                <button ejs-button [isPrimary]="false" class="footer-btn" cssClass="e-outline" type="button"
                    (click)="closeAlert(false,'CANCEL')">
                    {{alertData.cancelBtn ? alertData.cancelBtn : 'Cancel'}}
                </button>

                <button ejs-button [isPrimary]="true" class="footer-btn ml-10" type="button"
                    (click)="closeAlert(true,'CONFIRM')">
                    {{alertData.confirmBtn ? alertData.confirmBtn : 'Confirm'}}
                </button>
            </ng-container>
            <ng-template #alertBtn>
                <button ejs-button [isPrimary]="true" class="footer-btn" type="button"
                    (click)="closeAlert(true,'CONFIRM')">
                    {{alertData.confirmBtn ? alertData.confirmBtn : 'Ok'}}
                </button>
            </ng-template>
        </div>
    </ng-template>
</ejs-dialog>