import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { ListPayload } from "./my-notification.model";
import { Store } from "src/app/core/store/store";
import { environment } from "src/environments/environment";
import {
  GetRecordDetailsLoadedState,
  GetRecordDetailsLoadingErrorState,
  GetRecordDetailsLoadingState,
  MyNotificationLoadedState,
  MyNotificationLoadingErrorState,
  MyNotificationLoadingState,
} from "./my-notification.state";
import {
  MarkAllReadLoadedState,
  MarkAllReadLoadingErrorState,
  MarkAllReadLoadingState,
} from "./my-notification.state";
import {
  SingleReadLoadedState,
  SingleReadLoadingState,
  SingleReadLoadingErrorState,
} from "./my-notification.state";
import {
  GetAuditAreaDetailsLoadedState,
  GetAuditAreaDetailsLoadingState,
  GetAuditAreaDetailsLoadingErrorState,
} from "./my-notification.state";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class MyNotificationService extends Store.AbstractService {
  getNotifications(body: ListPayload): Observable<any> {
    const me = this;
    const output = new Subject<Store.State>();
    const userID = localStorage.getItem("userID");

    setTimeout(() => {
      output.next(new MyNotificationLoadingState());
    }, 0);

    const path = environment.api.notifications.getnotifications.endpoint;
    me.controller
      .post(path, body, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new MyNotificationLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new MyNotificationLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  MarkallasRead(): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    const userID = localStorage.getItem("userID");

    setTimeout(() => {
      output.next(new MarkAllReadLoadingState());
    }, 0);

    const path = environment.api.notifications.markAllRead.endpoint;

    me.controller
      .put(path, null, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new MarkAllReadLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new MarkAllReadLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  MarkSingleRead(payload: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new SingleReadLoadingState());
    }, 0);

    const path = environment.api.notifications.markSingleRead.endpoint;
    const body = { id: payload };
    me.controller
      .put(path, body, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new SingleReadLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new SingleReadLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  getFilterList() {
    const me = this;
    const path = environment.api.notifications.notificationFilterList.endpoint;
    return me.controller
      .get(path, null, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .pipe(map((json: Array<any>) => me.modifyJson(json)));
  }

  public modifyJson(jsonData): any[] {
    const obj = [];
    jsonData.data.forEach((v) => {
      obj.push({ label: v?.label, value: v?.code });
    });
    return obj;
  }

  GetAuditAreaDetails(body: any): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GetAuditAreaDetailsLoadingState());
    }, 0);

    const path = environment.api.notifications.getAuditAreaDetails.endpoint;

    me.controller
      .post(path, body, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new GetAuditAreaDetailsLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new GetAuditAreaDetailsLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }

  GetRecordDetails(recordId: string): Observable<Store.State> {
    const me = this;
    const output = new Subject<Store.State>();

    setTimeout(() => {
      output.next(new GetRecordDetailsLoadingState());
    }, 0);

    const path = environment.api.notifications.getRecordsDetails.endpoint;

    const body = { recordId: recordId };

    me.controller
      .post(path, body, environment.api.core.defaultBase, {
        Authorization: true,
      })
      .subscribe(
        (data: any) => {
          output.next(new GetRecordDetailsLoadedState(data));
          output.complete();
        },
        (e: any) => {
          output.error(new GetRecordDetailsLoadingErrorState(e));
          output.complete();
        }
      );

    return output;
  }
}
