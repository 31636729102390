export class AppError {
  constructor(
    public msg?: string,
    public code?: string,
    public error?: Error
  ) {}
  static fromError(error: Error): AppError {
    return new AppError(error.message, null, error);
  }
}
