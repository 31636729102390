import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'proMobileNumber'
})

export class ProMobileNumberPipe implements PipeTransform {
    transform(rawNum: string) {
        //  rawNum = "+1"+ rawNum;
        let areaCodeStr='';
        let midSectionStr='';
        let lastSectionStr='';

        if (rawNum) {
            areaCodeStr = rawNum.slice(0, 3);
            midSectionStr = rawNum.slice(3, 6);
            lastSectionStr = rawNum.slice(6 - 10);
        }

        return `${areaCodeStr}-${midSectionStr}-${lastSectionStr}`;
    }
}