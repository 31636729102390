import { Component, Input, ViewEncapsulation } from '@angular/core';
import { EllipsisPipe } from '../pipes/ellipsis/ellipsis.pipe';
@Component({
  selector: 'app-data-handler',
  templateUrl: './data-handler.component.html',
  styleUrls: ['./data-handler.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [EllipsisPipe],
})

export class DataHandlerComponent {
  @Input() public size;
  @Input() public fontSize;
  @Input() public fontClass = "open-sans-regular";
  @Input() public tooltipClass;
  @Input() public tooltip = true;
  @Input() public tooltipPosition = 'above';
  @Input() public defaultContent = 'NA';
  @Input() public content = '';

  ngOnInit(): void { }

  beforeOpen(args: any, element: any) {
    if ((!this.size && element.scrollWidth <= element.offsetWidth) || (this.content && this.size && this.content.length <= this.size) || !this.tooltip) {
      args.cancel = true;
    }
  }
}
