import { Component, OnInit, EventEmitter } from '@angular/core';
import { AlertData } from './service/alert.model';
import { AlertService } from './service/alert.service';
@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  showModal: boolean = false;
  alertData: AlertData;

  alertClosed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private alertService: AlertService) { }

  ngOnInit(): void { }

  public showAlert(alertData: AlertData) {
    this.alertData = alertData;
    this.showModal = true;
  }

  closeAlert(confirm: boolean, buttonType: string) {
    this.showModal = false;
    if (buttonType !== 'CLOSE') {
      this.alertClosed.emit(confirm);
    } else {
      this.triggerCloseEmitEvent(this.alertData.emitClose, confirm);
    }
    this.removeDialogFromDOM();
  }

  removeDialogFromDOM() {
    setTimeout(() => {
      this.alertService.removeAlert();
    }, 200);
  }

  triggerCloseEmitEvent(emitClose: boolean = true, confirm: boolean) {
    if (emitClose) {
      this.alertClosed.emit(confirm);
    }
  }

}
