import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { AlertData } from './alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  alerts: Array<ComponentRef<any>> = [];

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  showAlert(container: ViewContainerRef, componentClass: Type<any>, alertData: AlertData) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const component = container.createComponent(componentFactory);
    this.alerts.push(component);
    component.instance.showAlert(alertData);
    return component.instance;
  }

  removeAlert() {
    const lastAlert = this.alerts[this.alerts.length - 1];
    lastAlert.destroy();
    this.alerts.pop();
  }

}
