import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DialogComponent, PositionDataModel } from '@syncfusion/ej2-angular-popups';
import { SessionService } from 'src/app/core/session/session.service';
import { LogoutErrorState, LogoutLoadedState, LogoutLoadingState, oktaLogoutLoadedState, oktaLogoutLoadingErrorState, oktaLogoutLoadingState } from 'src/app/core/session/session.state';
import { Store } from 'src/app/core/store/store';
import { SnakbarService } from '../../common/snakbar/snakbar.service';
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { AlertComponent } from '../../alert/alert.component';
import { AlertService } from '../../alert/service/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-modal',
  templateUrl: './profile-modal.component.html',
  styleUrls: ['./profile-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})

export class ProfileModalComponent implements OnInit {
  @ViewChild('container', { read: ViewContainerRef }) container: ViewContainerRef;

  public profileDialog: DialogComponent;
  public profileCloseIcon: Boolean = true;
  public confirmWidth: string = '400px';
  public animationSettings: Object = { effect: 'Fade', duration: 400, delay: 0 };
  public target: string = '.control-section';
  showModal = false;
  public position: PositionDataModel = { X: 'center', Y: 'center' };
  data: any;
  error: boolean;
  empty: boolean;
  loading: boolean;
  @ViewChild('toast') toast: ElementRef;
  userDetails: any;
  userFullName: String;


  constructor(private router: Router, private alertService: AlertService, private sessionService: SessionService, private snakbarService: SnakbarService) { }

  ngOnInit(): void {
    const me = this;
    me.userDetails = AppLocalStorage.get('USER_DETAILS', 'USER_DETAILS');
  }

  ngAfterViewInit(): void {
    const me = this;
    document.onclick = (args: any): void => {
      if (args.target.tagName == 'DIV' || args.target.tagName == 'rect') {
        if (me.showModal == true) {
          me.showModal = false;
        }
      }
    }
  }

  public openDialog() { // For Opening the Profile Dialog
    const me = this
    me.showModal = true;
  }

  public closeDialog() { // For Closing the Dialog
    const me = this
    me.showModal = false;
  }

  public openProfile() { // Navigate to Profile Page
    const me = this;
    me.router.navigate(['profile']);
    me.closeDialog();
  }

  public openFaq() { // Navigate to FAQ Page
    const me = this;
    me.router.navigate(['faq']);
    me.closeDialog();
  }

  openAboutUs() {     //navigate to about us
    const me = this;
    me.router.navigate(['about-us']);
    me.closeDialog();
  }

  public openContact() { // Navigate to Contact Page
    const me = this;
    me.router.navigate(['contact']);
    me.closeDialog();
  }
  openlogout() {

    const ref = this.alertService.showAlert(this.container, AlertComponent, {
      isConfirmation: true,
      title: 'Are you sure',
      header: 'Logout',
      message: ' Do you want to logout from the system?',
      position: { X: 'center', Y: 'center' }
    });

    ref.alertClosed.subscribe((isConfirmed: boolean) => {
      if (isConfirmed) {
        this.logout();
      }
    })


  }



  public logout() {
    const me = this;

    me.sessionService.logout().subscribe(
      (state: Store.State) => {
        if (state instanceof LogoutLoadingState) {
          me.onLoading(state);
          return;
        }

        if (state instanceof LogoutLoadedState) {
          me.onLoaded(state);
          return;
        }
      },
      (state: LogoutErrorState) => {
        me.onLoadingError(state);
      }
    );

  }

  private onLoading(state: LogoutLoadingState) {
    const me = this;
    me.data = null;
    me.empty = false;
    me.error = null;
    me.loading = true;
  }

  private onLoadingError(state: LogoutErrorState) {

    const me = this;
    me.data = null;
    me.empty = false;
    me.error = true;
    me.loading = true;
  }

  private onLoaded(state: LogoutLoadedState) {
    const me = this;
    if (state.data?.errorCode == 0) {
      me.data = state.data;
      me.error = false;
      me.loading = false;
      if (me.data) {
        const basePath = environment.api.core.base
        me.sessionService.clear().subscribe(() => { });
        if (environment.protocol.key == "openIdOkta" || environment.protocol.key == "openIdAzure") {
          window.open("https://login.microsoftonline.com/5d0af39e-31d1-4069-9243-c30c29346af7/oauth2/v2.0/logout?post_logout_redirect_uri=https://traq-dev.leftrightmind.com/oidc-azure/", "_self")
        }
        else if (environment.protocol.key == "samlKeyCloak") {
          window.open(`${basePath}` + "/auth/saml/keycloak/login", "_self")
        }
        else if (environment.protocol.key == "samlOkta") {
          window.open(`${basePath}` + "/auth/saml/okta/login", "_self")
        }
        else if (environment.protocol.key == "openIdKeyCloak") {
          window.open(`${basePath}` + "/auth/oauth2/keycloak/login", "_self")
        }

          else if (environment.protocol.key == "defaultJWT") {
            me.snakbarService.showToast(me.toast.nativeElement, {
              title: 'Logged Out Successfully',
              position: {
                X: 'Right',
              },
              type: 'SUCCESS',
              showCloseButton: true
            });
          me.router.navigate(["logout"]);
          setTimeout(() => {

            me.router.navigate(["/login"]);

          }, 5000);
        }
      }
    }
    else {
      me.loading = false;
      me.snakbarService.showToast(me.toast.nativeElement, {
        title: state?.data?.message,
        type: "ERROR",
        showCloseButton: true,
      });
    }

  }



}
