import { MaterialModule } from 'src/app/shared/material/material-module';
import { SidenavComponent } from './sidenav.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations';
import { ProfileModalModule } from '../modal/profile-modal/profile-modal.module';

const component = [
  SidenavComponent
]

const imports = [
  CommonModule,
  RouterModule,
  MaterialModule,
  FlexLayoutModule,
  SidebarModule,
  ProfileModalModule,
  NgxPermissionsModule.forChild(),
]
@NgModule({
  declarations: [component],
  imports: imports,
  exports: [component],
})


export class SidenavModule { }





