import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgxMaskModule } from "ngx-mask";
import { NgxPermissionsGuard, NgxPermissionsModule } from "ngx-permissions";
import { SessionGuard } from "../core/session/session.guard";
import { CallbackComponent } from './callback/callback.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: "/dashboard",
    pathMatch: "full",
  },

  // Boot
 
  {
    path: "loading",
    loadChildren: () =>
      import("./loading/loading.module").then((m) => m.LoadingModule),
  },
  {
    path: "error",
    loadChildren: () =>
      import("./error/error.module").then((m) => m.ErrorModule),
  },
  {
    path: "callback",
    loadChildren: () =>
      import("./callback/callback.module").then((m) => m.CallbackModule),
  },
  {
    path: "activate",
    loadChildren: () =>
      import("./activation-page/activation-page.module").then((m) => m.ActivationPageModule),
  },

  {
    path: "login",
    loadChildren: () =>
      import("./login/login.module").then((m) => m.LoginModule),
  },
  {
    path: "logout",
    loadChildren: () =>
      import("./logout/logout.module").then((m) => m.LogoutModule),
  },
  {
    path: "access-denied",
    loadChildren: () =>
      import("./access-denied/access-denied.module").then(
        (m) => m.AccessDeniedModule
      ),
  },
  {
    path: "locked",
    loadChildren: () =>
      import("../pages/lock/lock.module").then((m) => m.lockModule),
  },
  // DO NOT ADD ANYTHING ABOVE UNLESS U KNOW WHAT U R DOING

  // Authenticated Routes

  {
    path: "",
    loadChildren: () => import("./home/home.module").then((m) => m.HomeModule),
  },

  {
    path: "**",
    redirectTo: "/error?e=ROUTE_NOT_FOUND",
  },
  

  // DO ADD ANYTHING BELOW THIS LINE
];

@NgModule({
  declarations: [

  ],
  imports: [
    RouterModule.forRoot(routes, { useHash: false, enableTracing: false }), // UseHash should be false, for msal to work
    NgxPermissionsModule.forChild(),
    NgxMaskModule.forRoot(),
  ],
  providers: [NgxPermissionsGuard, SessionGuard],
  exports: [RouterModule],
})
export class PagesModule {}
