import { Store } from 'src/app/core/store/store';
import { NotificationsComponent } from '../notifications.component';
import { AuditAreaDetails, NotificationList } from './my-notification.model';

export class MyNotificationLoadingState extends Store.AbstractLoadingState<NotificationList> { }
export class MyNotificationLoadingErrorState extends Store.AbstractErrorState<NotificationList> { }
export class MyNotificationLoadedState extends Store.AbstractIdealState<NotificationList> { }

 export class MarkAllReadLoadingState extends Store.AbstractLoadingState<any> { }
 export class MarkAllReadLoadingErrorState extends Store.AbstractErrorState<any> { }
 export class MarkAllReadLoadedState extends Store.AbstractIdealState<any> { }

 export class SingleReadLoadingState extends Store.AbstractLoadingState<any> { }
 export class SingleReadLoadingErrorState extends Store.AbstractErrorState<any> { }
 export class SingleReadLoadedState extends Store.AbstractIdealState<any> { }

 export class GetAuditAreaDetailsLoadingState extends Store.AbstractLoadingState<any>{}
 export class GetAuditAreaDetailsLoadingErrorState extends Store.AbstractErrorState<any>{}
 export class GetAuditAreaDetailsLoadedState extends Store.AbstractIdealState<any>{}

 export class GetRecordDetailsLoadingState extends Store.AbstractLoadingState<any>{}
 export class GetRecordDetailsLoadingErrorState extends Store.AbstractErrorState<any>{}
 export class GetRecordDetailsLoadedState extends Store.AbstractIdealState<any>{}
