import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PagesModule } from './pages/pages.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoggerModule } from 'ngx-logger';
import { SessionService } from './core/session/session.service';
import { sessionProviderFactory } from './core/session/session.module';
import { InjectorResolver } from './core/injector/injector.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { SidenavModule } from './shared/sidenav/sidenav.module';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import {EncrDecrService} from './shared/common/encryption-decryption/encryption-decryption.service';
import { RemovelockGuard } from './pages/objectives/questionnaire/guards/removelock.guard';

@NgModule({
  declarations: [
    AppComponent,
    ],
  imports: [
    PagesModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    LoggerModule.forRoot(null),
    SidenavModule,
    DashboardLayoutModule,
  ],
  providers: [
    SessionService,
    EncrDecrService,
    // RemovelockGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: sessionProviderFactory,
      deps: [InjectorResolver, SessionService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
