import { Pipe, PipeTransform } from "@angular/core";
import * as _moment from "moment";
@Pipe({
  name: "activityDatePipe",
})
export class ActivityDatePipe implements PipeTransform {
  transform(input: string, formatType: string): any {
    const me = this;

    if (!input) {
      return null;
    }
    const format = me.dateFormat(formatType);
    const dateTimeRegex =
      /(?:.*\s)?([A-Z][a-z]{2}\s){2}\d{1,2}\s\d{2}:\d{2}:\d{2}\s[A-Z]{3}\s\d{4}(?:\s.*)?/;
    const matchedDateTime = input.match(dateTimeRegex);
    var inputString = input;

    if (!matchedDateTime || matchedDateTime.length === 0) {
      return inputString;
    }

    const momentDateTime = _moment
      .utc(matchedDateTime[0])
      .local()
      .format(format);
    var inputString = `${
      input.split("deadline to")[0]
    } deadline to ${momentDateTime}`;

    return inputString;
  }

  dateFormat(formatType: string): string {
    switch (formatType) {
      case "shortDate":
        return "DD MMM YYYY";
      case "normalDate":
        return "DD MMM YY";
      case "normalDateWithTime":
        return "d/M/yy h:mm a";
      case "normalTimeWithDate":
        return "h:mm A, DD/MM/yyyy";
      case "normalTimeWithDateComma":
        return "hh:mm A, DD MMM yyyy";
      case "monthFirstWithDate":
        return "MMM DD,YYYY hh:mm:ss A";
      case "normalTimeWithDateNoComma":
        return "h:mm A DD/MM/yyyy";
      case "fullTimeAndDate":
        return "DD MMM YY HH:mm";
      case "mediumDate":
        return "DD MMM YYYY  hh:mm A";
      case "longDate":
        return "DD MMM YYYY hh:mm:ss A";
      case "normalDateWithDay":
        return "dddd, d/M/yy";
      case "yearMonthDate":
        return "YY-MM-DD";
      case "longTime":
        return "hh:mm:ss A";
      case "mediumTime":
        return "hh:mm A";
      case "FullYearDateAndTime":
        return "YYYY-MM-DD HH:mm:ss";
      case "FullYearDate":
        return "YYYY-MM-DD";
      case "timeWithDate":
        return "hh:mm A | DD MMM";
    }
  }
}

export const DateFormat = {
  parse: {
    dateInput: "input",
  },
  display: {
    dateInput: "DD MMM YY",
    monthYearLabel: "MMM YYYY",
  },
};

export const GlobalMatDatePickerFormatProvider = [];

@Pipe({
  name: "dateAgo",
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);

      // const hour = Math.floor((+new Date() - +new Date(value)) / (h * 60 * 60 * 1000));

      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return "just now";
      // if (seconds > 3599 && seconds < 7200)
      //     return '1 hour ago'
      // if (seconds > 3600 && seconds < 86400) {
      //     return 'today';
      // }
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1,
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + " " + i + " ago"; // singular (1 day ago)
          } else {
            return counter + " " + i + "s ago"; // plural (2 days ago)
          }
      }
    }
    return value;
  }
}

const datetimeString = "Thu Apr 20 05:30:00 UTC 2023";
const datetimeRegex =
  /^([A-Z][a-z]{2}\s){2}\d{1,2}\s\d{2}:\d{2}:\d{2}\s[A-Z]{3}\s\d{4}$/;

if (datetimeRegex.test(datetimeString)) {
  // console.log("The string contains a valid datetime.");
} else {
  // console.log("The string does not contain a valid datetime.");
}
