export class GlobalConstants {
  public static AuditAreas: string = "Main Areas";

  public static AuditArea: string = "Main Area";

  public static subAuditAreas: string = "Sub Areas";

  public static subAuditArea: string = "Sub area";

  public static SysAdmin: string = "System Admin";

  public static Auditor: string = "Executive User";

  public static Auditee: string = "Associate User";
  
  public static Trigger: string = "Automation";

  public static Triggers: string = "Automations";

  public static condition: string = "Criteria";

  public static Actions : string = "Actions";

  public static Action : string = "Action";

  public static Frequency: string = "Schedule";
}
