<div class="control-section">
    <ejs-dialog #profileDialog CloseOnEscape [visible]="showModal" [animationSettings]='animationSettings' [target]='target'
        [width]='confirmWidth' [position]="position">
        <ng-template #content>
            <!-- Profile Details Section START -->
            <div class="profile-info ph-20" fxLayout="row" fxLayoutAlign="start center">
                <div class="initials-div" fxLayout="column" fxLayoutAlign="start start" fxFlex="20">
                    <span class="initials">{{userDetails?.fullname | shortName:2}}</span>
                </div>
                <div fxLayout="column" fxLayoutAlign="start start" fxFlex="70" fxFlexOffset="5">
                    <p class="font-size-15 montserrat-Bold mv-10">{{userDetails?.firstname}} {{userDetails?.lastname}}
                        <span>
                            <app-data-handler [size]="35" [content]="userDetails?.email" [fontSize]="13"
                                [fontClass]="'open-sans-regular'">
                            </app-data-handler>
                        </span>
                    </p>
                    <p class="open-sans-regular font-size-13 mv-5">{{userDetails?.role}}</p>
                </div>
            </div>
            <!-- Profile Details Section END -->

            <!-- Separator START -->
            <div class="middle-line">

            </div>
            <!-- Separator END -->
            <!-- Profile Modal Link Section START -->
            <div class="profile-links ph-20">
                <div class="profile-link" fxLayout="row" (click)="openProfile()">
                    <img src="assets/custom/account-profile.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">My Info</p>
                </div>
                <div class="profile-link" fxLayout="row" (click)="openContact()">
                    <img src="assets/custom/noun-help-support.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">Help & support</p>
                </div>


                <div class="profile-link" fxLayout="row" (click)="openAboutUs()">
                    <img src="assets/custom/noun-help-faq.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">About Us</p>
                </div>

                <div class="profile-link" fxLayout="row" (click)="openFaq()">
                    <img src="assets/custom/noun-help-faq.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">FAQ's</p>
                </div>

                <div class="profile-link" fxLayout="row" (click)="openlogout()">
                    <img src="assets/custom/noun-logout.svg" width="25px">
                    <p class="open-sans-bold font-size-14 mv-10">Logout</p>
                    <ng-template #container></ng-template>
                </div>
            </div>
            <!-- Profile Modal Link Section END -->
        </ng-template>
    </ejs-dialog>
    <div id="toast" #toast></div>
</div>