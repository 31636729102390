import { Subject } from "rxjs";


export interface MessagingMessage {
    action?: string;
    author?: string;
    session?: string;
    tabSession?: string;
    sameAuthor?: boolean;
    sameSession?: boolean;
    sameTabSession?: boolean;
    source?: string;
    code: string;
    payload?: any;
    count:string;
}

export class MessageObserver<T> extends Subject<T> {
    private onUnsubscribeCallback: () => void;

    onUnsubscribe(callback: () => void) {
        this.onUnsubscribeCallback = callback;
    }

    unsubscribe(): void {
        this.onUnsubscribeCallback();
        super.unsubscribe();
    }
}