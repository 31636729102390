import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'mobileNumber'
})

export class MobileNumberPipe implements PipeTransform {
    transform(rawNum: string) {
        //  rawNum = "+1"+ rawNum;

        const countryCodeStr = '+1';
        const areaCodeStr = rawNum.slice(0, 3);
        const midSectionStr = rawNum.slice(3, 6);
        const lastSectionStr = rawNum.slice(6 - 10);

        return `${countryCodeStr}${areaCodeStr}-${midSectionStr}-${lastSectionStr}`;
    }
}