import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "replaceLineBreaks" })
export class ReplaceLineBreaks implements PipeTransform {
  transform(value: string): string {
    let msgArray = value.split("\n");
    msgArray.shift();

    let msgString = "";

    msgArray.forEach(function (element) {
      if (msgArray[msgArray.length - 1] == element) {
        msgString += element.replace(/[.,]/g, "") + ".";
      } else {
        msgString += element.replace(/[.,]/g, "") + " And ";
      }
    });

    return msgString;
  }
}
