import { Pipe, PipeTransform } from '@angular/core';
import * as moment from "moment";

@Pipe({
  name: 'isDate'
})
export class IsDatePipe implements PipeTransform {

  transform(value: any, formatType: string): any {
    if (value == undefined || value === null) {
      return 'NA';
    }

    if (typeof value !== 'string') {
      return value;
    }

    if (!isNaN(value as any)) {
      return value;
    }

    if (moment(value,moment.ISO_8601, true).isValid()) {
      const format = this.dateFormat(formatType);
      return moment.utc(value).local().format(format);
    } else {
      return value;
    }
  }

  dateFormat(formatType: string): string {
    switch (formatType) {
      case 'shortDate':
        return 'DD MMM YYYY';
      case 'shortDateWithDash':
        return 'hh:mm A, DD MMM YYYY';
      case 'normalDate':
        return 'DD MMM YY';
      case 'normalDateWithTime':
        return 'd/M/yy h:mm a';
      case 'normalTimeWithDate':
        return 'h:mm A, DD/MM/yyyy';
      case 'normalTimeWithDateComma':
        return 'hh:mm A, DD MMM yyyy';
      case 'monthFirstWithDate':
        return 'MMM DD,YYYY hh:mm:ss A'
      case 'normalTimeWithDateNoComma':
        return 'h:mm A DD/MM/yyyy';
      case 'fullTimeAndDate':
        return 'DD MMM YY HH:mm';
      case 'mediumDate':
        return 'DD MMM YYYY  hh:mm A';
      case 'longDate':
        return 'DD MMM YYYY hh:mm:ss A';
      case 'normalDateWithDay':
        return 'dddd, d/M/yy';
      case 'yearMonthDate':
        return 'YY-MM-DD';
      case 'longTime':
        return 'h:mm:ss a';
      case 'mediumTime':
        return 'hh:mm A';
      case 'FullYearDateAndTime':
        return 'YYYY-MM-DD HH:mm:ss';
      case 'FullYearDate':
        return 'YYYY-MM-DD';
    }
  }

}
