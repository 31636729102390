import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'propertyName'
})
export class PropertyNamePipe implements PipeTransform {
  transform(property: string): any {
    return property
      .split('_')
      .map((word) => word[0].toUpperCase() + word.slice(1, word.length).toLowerCase())
      .join(' ')
  }
}
