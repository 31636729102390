import { Component, ViewEncapsulation } from '@angular/core';
import * as moment from 'moment-timezone';
import { SessionService } from './core/session/session.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {

  loggedIn = false;

  socket: WebSocket;

  constructor(public session: SessionService,
    private router: Router
  ) {
    window.moment = moment;
  }

  private ignoredRoutes: string[] = [
    "/callback?isAuthenticated=true", "/locked", "/access-denied", "/logout",
    "/login", "/activate", "/error", "/loading"
  ];
  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(!this.ignoredRoutes.includes(event.url)) {
          // Store the latest route in local storage
          localStorage.setItem('lastRoute', event.url);
        }
      }
    });

  }

}
