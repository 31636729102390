import { NgModule } from "@angular/core";
import { DataSuffixPipe } from "./data-suffix/data-suffix.pipe";
import { DatePipe, DateAgoPipe } from "./date-pipe/date.pipe";
import { EllipsisPipe } from "./ellipsis/ellipsis.pipe";
import { FileSizePipe } from "./file-size-pipe/file-size.pipe";
import { MobileNumberPipe } from "./mobile-number-pipe/mobile-number.pipe";
import { ProMobileNumberPipe } from "./mobile-number-pipe/proMobile-number-pipe";
import { ShortNamePipe } from "./short-name/short-name.pipe";
import { SortByPipe } from "./sort-by/sort-by.pipe";
import { PropertyNamePipe } from "./property-name/property-name.pipe";
import { IsDatePipe } from "./is-date/is-date.pipe";
import { ReplaceLineBreaks } from "./new-line-pipe/ReplaceLineBreaks.pipe";
import { ActivityDatePipe } from "./activity-date-pipe/activity-date-pipe";

const pipes = [
  DatePipe,
  EllipsisPipe,
  FileSizePipe,
  DataSuffixPipe,
  DateAgoPipe,
  SortByPipe,
  MobileNumberPipe,
  ProMobileNumberPipe,
  ShortNamePipe,
  PropertyNamePipe,
  IsDatePipe,
  ReplaceLineBreaks,
  ActivityDatePipe,
];

@NgModule({
  declarations: [pipes],
  exports: [pipes],
})
export class PipesModule {}
