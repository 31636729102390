import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { ProfileModalComponent } from './profile-modal.component';
import { PipesModule } from '../../pipes/pipes.module';
import { DataHandlerModule } from '../../data-handler/data-handler.module';


const components = [ProfileModalComponent];
const modules = [CommonModule, FlexLayoutModule,DialogModule, PipesModule, DataHandlerModule];

@NgModule({
  declarations: [components],
  imports: [modules],
  exports: [components]
})
export class ProfileModalModule { }
