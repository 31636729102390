import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppLocalStorage } from "src/app/utility/local-storage.util";
import { EncrDecrService } from "src/app/shared/common/encryption-decryption/encryption-decryption.service";
import { environment } from "src/environments/environment";
import { SnakbarService } from "src/app/shared/common/snakbar/snakbar.service";
import { Router } from "@angular/router";
import { SessionService } from "../session/session.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private snakbarService: SnakbarService,
    private EncrDecr: EncrDecrService,
    private router: Router,
    public session: SessionService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const me = this;

    // Encrypt the body. excluded the document upload api
    const shouldDecryptRes = !(request.url.includes("/myorg/reports") || request.url.includes("/myorg/dashboards"))
      ;
    const updatedReq =
      request.url.includes("/questionnaire/question/publish") ||
        request.url.includes("/document/upload") ||
        request.url.includes("/support/raise-request") ||
        request.url.includes("/myorg/reports") ||
        request.url.includes("/myorg/dashboards")

        ? request
        : request.clone({
          body: this.encryptBody(request),
        });

    return next.handle(updatedReq).pipe(
      map((event: HttpEvent<any>) => {
        // If the map event is not HttpResponse, ignore it.
        if (!(event instanceof HttpResponse)) {
          return event;
        }

        // If Http status code is not 200, ignore it.
        if (event.status !== 200) {
          return event;
        }

        // Decrypt the body of Http response.
        return this.decryptResponse(event, shouldDecryptRes);
        // return event;
      }),
      catchError((err) => {
        if (err.status == 500) {
          me.snakbarService.showToast(
            document.getElementById("appErrorToast"),
            {
              title: "Internal Server Error",
              position: {
                X: "Right",
              },
              type: "ERROR",
              showCloseButton: true,
            }
          );
        } else if (err.status == 403) {
          //AppLocalStorage.clear('SESSION', 'SESSION');
          me.session.clear().subscribe(() => { });
          if (!AppLocalStorage.get("SESSION", "SESSION")) {
            let apiTimeOut;
            if (apiTimeOut) {
              clearTimeout(apiTimeOut);
            }
            apiTimeOut = setTimeout(() => {
              me.snakbarService.showToast(
                document.getElementById("appErrorToast"),
                {
                  title: "Your session has expired please login again",
                  position: {
                    X: "Right",
                  },
                  type: "ERROR",
                  showCloseButton: true,
                }
              );
              me.router.navigate(["/login"]);
            }, 1000);
          }

          let errResponse = this.EncrDecr.get(
            environment.encryptionDecryption.key,
            err.error?.response
          );
          if (errResponse?.code == 1011) {
            this.router.navigate(["/locked"]);
          }
        } else if (err.status == 0) {
          me.snakbarService.showToast(
            document.getElementById("appErrorToast"),
            {
              title: "Internal Server Error",
              position: {
                X: "Right",
              },
              type: "ERROR",
              showCloseButton: true,
            }
          );
        }
        const error = err;
        // return throwError(error)
        return throwError(this.decryptResponse(error, shouldDecryptRes));
      })
    );
  }

  private encryptBody(req: HttpRequest<any>) {
    const encryptedPayload = {
      data: this.EncrDecr.set(environment.encryptionDecryption?.key, req.body),
    };

    return encryptedPayload;
  }

  private decryptResponse(
    response: HttpResponse<any>,
    shouldDecryptRes: boolean
  ) {
    const res: any = response;
    let decryptedResponse;
    if (shouldDecryptRes) {
      decryptedResponse = this.EncrDecr.get(
        environment.encryptionDecryption.key,
        !!res.body ? res.body.response : res.error.response
      );
    }

    if (!!res.body) {
      return new HttpResponse({
        ...res,
        body: decryptedResponse ?? res.body,
      });
    } else {
      return {
        ...res,
        error: decryptedResponse,
      };
    }
  }
}
